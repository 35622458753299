// import { Link } from 'react-router-dom'
// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardGroup,
//   CCol,
//   CContainer,
//   CForm,
//   CFormInput,
//   CFormLabel,
//   CInputGroup,
//   CInputGroupText,
//   CRow,
// } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
// import { cilLockLocked, cilUser } from '@coreui/icons'
// import React, { useEffect, useState } from 'react';
// // import { Card, Row, Col, Button, Checkbox, Form, Input } from 'antd';
// import service from 'src/auth/FetchInterceptor';
// import { useNavigate } from 'react-router-dom';
// // import { CAlert, CCol } from '@coreui/react';
// const backgroundStyle = {
//   //   backgroundImage: 'url(/img/others/img-17.jpg)',
//   backgroundColor: 'red',
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'cover',
//   //   heigth,
// };

// const LoginOne = props => {
//   const navigate = useNavigate();
//   const [validated, setValidated] = useState(false);
//   const [Flag, SetFlag] = useState(false);
//   const[user_name,setUserName]=useState("");
//   const[password,setPassword]=useState("");


//   const [adminData, setAdminData] = useState([]);

//   const handleSubmit = (event) => {

//     const form = event.currentTarget

//     if (form.checkValidity() === false) {

//       event.preventDefault()

//       event.stopPropagation()

//     }
//     else
//     {
//        if(user_name==adminData[0].name&&password==adminData[0].password)
//        {
//         console.log('hi');
//         localStorage.setItem('isLoggedIn', 'true');
//         navigate('/dashboard');
//        }
//        else
//        {
//         console.log("Wrong")
//        }      
//     }

//     setValidated(true)

//   }
//   const login_api = async () => {
//     try {
//       const res = await service.get('getadmins');
//       if (res.status === 200) {
//         // console.log(res.data?.data);
//         setAdminData(res.data.data);
//         console.log("Data is===> "+adminData[0].name)
//       } else {
//         // console.log(res.data);
//       }
//     } catch (error) {
//       console.log('error', error);
//     }
//   };
//   useEffect(() => {
//     const isLogged = localStorage.getItem('isLoggedIn');
//     if (isLogged) {
//       // console.log('isLogged', isLogged);
//       navigate('/dashboard');
//     } else {
//       navigate('/login');
//       login_api();
//     }
//   }, []);

//   const onFinish = values => {
//     console.log('Success:', values);
//     console.log('data', adminData);
//     if (user_name == values.username && password == values?.password) {
//       console.log('hi');
//       localStorage.setItem('isLoggedIn', 'true');

//       navigate('/dashboard');
//     } else {
//       SetFlag("wrong");
//       console.log('wrong');
//     }
//   };
//   const onFinishFailed = errorInfo => {
//     console.log('Failed:', errorInfo);
//   };
//   return (
//     <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
//       <CContainer>
//         <h1 style={{ textAlign: "center", padding: 20, fontWeight: "bold" }}>AH-Project</h1>
//         <CRow className="justify-content-center">
//           <CCol md={5}>
//             <CCardGroup>
//               <CCard className="p-4">
//                 <CCardBody>
//                   <CForm className="row g-3 needs-validation"
//                     noValidate
//                     validated={validated}
//                     onSubmit={handleSubmit}>
//                     <h1 style={{ textAlign: "center", padding: 15, fontSize: 30 }}>SIGN IN</h1>
//                     {/* <p className="text-medium-emphasis">Sign In to your account</p> */}
//                     <CInputGroup className="mb-3">

//                       <CInputGroupText>
//                         <CIcon icon={cilUser} />
//                       </CInputGroupText>
//                       <CFormInput placeholder="Username" autoComplete="username"
//                         required
//                         value={user_name}
//                         onChange={(val) => {
//                           setUserName(val.target.value);
//                         }}
//                       />
//                     </CInputGroup>
//                     <CInputGroup className="mb-4">
//                       <CInputGroupText>
//                         <CIcon icon={cilLockLocked} />
//                       </CInputGroupText>
//                       <CFormInput
//                         type="password"
//                         placeholder="Password"
//                         autoComplete="current-password"
//                         required
//                         value={password}
//                         onChange={(val) => {
//                           setPassword(val.target.value);
//                         }}
//                       />
//                     </CInputGroup>
//                     <CRow>
//                       <CCol xs={6}>
//                         <button
//                           style={{ width: 80, height: 40, border: 'none', backgroundColor: "#039a83", borderRadius: 10, color: "#fff" }}>
//                           Login
//                         </button>
//                       </CCol>
//                       <CCol xs={6} className="text-right">
//                         <CButton color="link" className="px-0">
//                           Forgot password?
//                         </CButton>
//                       </CCol>
//                     </CRow>
//                   </CForm>
//                 </CCardBody>
//               </CCard>

//             </CCardGroup>
//           </CCol>
//         </CRow>
//       </CContainer>
//     </div>
//   )
//   // return (
//   //   <div
//   //     //   className="h-100"
//   //     style={{
//   //       backgroundRepeat: 'no-repeat',
//   //       backgroundSize: 'cover',
//   //       padding: 150,
//   //       margin: 'auto auto',
//   //     }}
//   //   >
//   //     <div className="container d-flex flex-column justify-content-center h-100">
//   //       <Row justify="center">
//   //         <Col xs={20} sm={20} md={20} lg={7}>
//   //           <Card>
//   //             <div className="my-4">
//   //               <div className="text-center">
//   //                 <img
//   //                   style={{ maxWidth: 230 }}
//   //                   className="img-fluid "
//   //                   // src={`/${theme === 'light' ? 'image.ico' : 'image.ico'}`}
//   //                   alt=""
//   //                 />

//   //               </div>
//   //               <Row justify="center">
//   //                 <Col xs={24} sm={24} md={20} lg={20}>
//   //                   <Form
//   //                     name="basic"
//   //                     labelCol={{
//   //                       span: 8,
//   //                     }}
//   //                     wrapperCol={{
//   //                       span: 16,
//   //                     }}
//   //                     initialValues={{
//   //                       remember: true,
//   //                     }}
//   //                     onFinish={onFinish}
//   //                     onFinishFailed={onFinishFailed}
//   //                     autoComplete="off"
//   //                   >
//   //                     <Form.Item
//   //                       label="Username"
//   //                       name="username"
//   //                       rules={[
//   //                         {
//   //                           required: true,
//   //                           message: 'Please input your username!',
//   //                         },
//   //                       ]}
//   //                     >
//   //                       <Input />
//   //                     </Form.Item>

//   //                     <Form.Item
//   //                       label="Password"
//   //                       name="password"
//   //                       rules={[
//   //                         {
//   //                           required: true,
//   //                           message: 'Please input your password!',
//   //                         },
//   //                       ]}
//   //                     >
//   //                       <Input.Password />
//   //                     </Form.Item>

//   //                     <Form.Item
//   //                       name="remember"
//   //                       valuePropName="checked"
//   //                       wrapperCol={{
//   //                         offset: 8,
//   //                         span: 16,
//   //                       }}
//   //                     >

//   //                     </Form.Item>

//   //                     <Form.Item
//   //                       wrapperCol={{
//   //                         offset: 8,
//   //                         span: 16,
//   //                       }}
//   //                     >
//   //                       <button
//   //                         style={{ width: 80, height: 40, border: 'none', backgroundColor: "#039a83", borderRadius: 10, color: "#fff" }}>
//   //                         Submit
//   //                       </button>

//   //                       {Flag == 'wrong' ?
//   //                       <>
//   //                       <h6 style={{marginLeft:20,color:"#f00", marginTop:5}}>wrong</h6>
//   //                       </>
//   //                       :null
//   //                       }


//   //                     </Form.Item>
//   //                   </Form>
//   //                 </Col>
//   //               </Row>
//   //             </div>
//   //           </Card>
//   //         </Col>
//   //       </Row>
//   //     </div>
//   //   </div>
//   // );
// };

// export default LoginOne;

import { Link, Route, useNavigate } from "react-router-dom";
import { useState, useRef, useContext, useEffect } from "react";

import styles from "../css/login.module.css";
import login_img from '../login/login.svg';
import { Gallery } from "react-grid-gallery";
import service from 'src/auth/FetchInterceptor';

const Login = () => {

  const navigate = useNavigate();
  

  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");

  // Input Refs
  const emailInputRef = useRef();
  const passwordInputRef = useRef();


  const [isThereloginError, setIsThereloginError] = useState(false);
  const [overallLoginError, setOverallLoginError] = useState(null);



  const login_api = async () => {
    try {
     let data={
       email:user_name,
       password:password
     }
      const res = await service.post('admin/admin/login',data);
      if (res.status === 200) {
        console.log(res.data.msg)
        if(res.data.msg==="done")
        {
          localStorage.setItem('isLoggedIn', 'true');
          navigate('/dashboard');
        }
        else
        {
           console.log("error");
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const isLogged = localStorage.getItem('isLoggedIn');
    if (isLogged) {
       navigate('/dashboard');
    } else {
      navigate('/login');
    
    }
  }, []);


  return (
    <>
    <div className={styles.loginWrapper}>
        <div className={styles.loginContainer}>
          <div className={styles.logingGrid}>
            <div className={styles.leftBox}>
              <img className={styles.svgLogin} src={login_img} alt="login svg icon" />
            </div>
            <div className={styles.rightBox}>
              <h1 className={styles.title}>AH-Project</h1>

              <form
                onSubmit={login_api}
                className={styles.formWrapper}
              >
                <div className={styles.formControl}>
                  <label className={styles.label} htmlFor="email">
                    UserName
                  </label>
                  <input
                    className={styles.formInput}
                    type="text"
                    required
                    value={user_name}
                    onChange={(val) => {
                      setUserName(val.target.value);
                    }}
                    autoCapitalize="off"
                    autoComplete="off"
                  />
                </div>
                <div className={styles.formControl}>
                  <div className={styles.labelGroup}>
                    <label className={styles.label} htmlFor="password">
                      Password
                    </label>
                    <Link
                      to="/login/forget"
                      className={styles.forgotPassword}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <input
                    className={styles.formInput}
                    type="password"
                    required
                    value={password}
                    onChange={(val) => {
                      setPassword(val.target.value);
                    }}
                    id="password"
                    autoCapitalize="off"
                    autoComplete="off"
                  />
                </div>

                {/* ERROR MESSAGE HERE */}
                {isThereloginError && (
                  <p id="errorMessage" className={styles.errorMessage}>
                    <ul className={styles.errorMsgList}>
                      <li>{overallLoginError}</li>
                    </ul>
                  </p>
                )}

                <button className={styles.loginBtn}>Sign in</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Login;

import axios from 'axios';

const service = axios.create({
  baseURL: 'https://app.ahproject.net/api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});


export default service;

import React, { Component, Suspense, useEffect } from 'react';
import { HashRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './scss/style.scss';
import Login from './views/login/login';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));



const App = () => {
  // const navigate = useNavigate();

  useEffect(() => {
  
  }, []);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
